import { useEffect, useRef } from 'react';
import './Start.scss';
import win95Icon from '../../assets/win95.png';
import StartMenu from '../StartMenu/StartMenu';

const StartButton = ({ isStartMenuOpen, toggleStartMenu, openWindow, isMusicPlaying }) => {
  const buttonClass = isStartMenuOpen ? "start-button-pressed" : "start-button";
  const buttonClickedRef = useRef(false);

  const handleMenuItemClick = (title, icon, content, name) => {
    openWindow(title, icon, content, name);
  }
  
  useEffect(() => {
    function handleClick(event) {
      if (event.target.closest('.start-button')) {
        if (buttonClickedRef.current) {
          buttonClickedRef.current = false;
        } else {
          buttonClickedRef.current = true;
          toggleStartMenu(!isStartMenuOpen);
        }
      } else if (!event.target.closest('.menu') && isStartMenuOpen) {
        buttonClickedRef.current = false;
        toggleStartMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [toggleStartMenu, isStartMenuOpen]);

  
  return (
      <>
          <button className={buttonClass}>
              <img alt='Windows-95-icon' src={win95Icon} className='win95-icon' /> 
              Start
          </button>
          
          {isStartMenuOpen && <StartMenu onMenuItemClick={handleMenuItemClick} isMusicPlaying={isMusicPlaying} />
}
      </>
  );
}

export default StartButton;
