import profilePic from '../assets/profile.png'

const aboutMeTabs = [
    { id: 'hello', label: 'Hello', 
        content: <div className="aboutme-hello">
            <div className='aboutme-name'>
                <span className="aboutme-firstname">Kais<span className="aboutme-lastname">Riahi</span></span>
            </div>
            <div className='aboutme-info'>
                <span className="aboutme-job">Software engineer</span>
                <span className="aboutme-address">Paris, France</span>
            </div>
        </div> },
    { id: 'skills', label: 'Skills', 
        content: <div className='aboutme-skills'>
            <div className='aboutme-skills-title'> 
                Tech I've Built With
            </div>
            <div className='aboutme-skills-desc'> 
                Java, Spring, Spring boot, Junit, Mockito, Microservices, Javascript, Typescript, ReactJS, Bootstrap, Sass, Jenkins, Gitlab-CI, Serverless (Azure Functions), Firebase, Docker, CSS3, HTML5, PostgreSQL, MySql, CouchDB, Git
            </div>
        </div> },
    { id: 'social', label: 'Social', 
        content: <div className='aboutme-social'>
            <div className='aboutme-social-title'> Profiles</div>
            <a href='https://www.behance.net/devkais' target="_blank" rel="noreferrer">Behance</a>
            <a href='https://www.linkedin.com/in/riahikais/' target="_blank" rel="noreferrer">LinkedIn</a>
            <a href='https://medium.com/@riahikais' target="_blank" rel="noreferrer">Medium</a>
            <br/>
            <br/>
            <a href='#'>riahikais@yahoo.com</a>
        </div> }];

export default aboutMeTabs;