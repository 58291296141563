import { useState } from "react";
import Draggable from "react-draggable";
import closeIcon from '../../assets/close.png';
import agrandirIcon from '../../assets/agrandir.png';
import minIcon from '../../assets/min.png';
import './AboutMeWindow.scss';
import aboutMeTabs from '../../utils/aboutme-data'; // Update path as needed

function AboutMeWindow({  onClose,onMinimize, onWindowClick, id, style, position }) {
    const [windowPos, setWindowPos] = useState(position);
    const [activeTab, setActiveTab] = useState('hello');
    const handleDragStop = (e, data) => {
        setWindowPos({ x: data.x, y: data.y });
    };
    return (
        <Draggable
            handle=".title-bar"
            bounds="parent"
            defaultPosition={windowPos}
            disabled={false}
            onStop={handleDragStop} 
        >
            <div className="win95-window aboutme-win-window" onTouchStart={() => onWindowClick(id)} onClick={() => onWindowClick(id)} style={{ ...style }}>
                <div className="title-bar">
                    <span className="title">
                        System properties
                    </span>
                    <div className='btns'>
                        <img src={minIcon} alt='min icon' onClick={onMinimize} onTouchStart={onMinimize}/>
                        <img src={agrandirIcon} alt='maximize icon'  />
                        <img src={closeIcon} alt='close icon' onClick={onClose} onTouchStart={onClose}/>
                    </div>
                </div>
                <div className="aboutme-content">
                    <div className="tabs">
                        {aboutMeTabs.map(tab => (
                            <button 
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                onTouchStart={() => setActiveTab(tab.id)}
                                className={tab.id === activeTab ? 'active tab' : 'tab'}
                            >
                                {tab.label}
                            </button>
                        ))}
                            <button className='empty-tab tab'></button>
                    </div>

                    <div className="tabs-content">
                        {aboutMeTabs.find(tab => tab.id === activeTab).content}
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default AboutMeWindow