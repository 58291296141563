import './StartMenu.scss';
import menuItems from './../../utils/menu-data';  // Importing the menuItems

function StartMenu({ onMenuItemClick, isMusicPlaying }) {
    const currentMenuItems = menuItems(isMusicPlaying);
    return (
        <>
            <div className='menu'>
                <div className='left-side'>
                    <p><span>ka1s</span>.dev</p>
                </div>
                <div className='right-side'>
                    {currentMenuItems.map(item => (
                        <>
                          { item.name === "Gallery" ? 
                            <div className='menu-item' key={item.name}>
                                <img src={item.icon} alt={item.name} className='icon' /> 
                                <a href="https://www.behance.net/devkais" target="_blank" rel="noopener noreferrer">
                                    {item.name} </a>
                            </div>
                            
                            : 
                            <div className='menu-item' key={item.name} onClick={() => onMenuItemClick(item.title, item.icon, item.content, item.name)}>
                                <img src={item.icon} alt={item.name} className='icon' /> 
                                <span>{item.name}</span>
                            </div>

                            }

                            
                        </>
                      
                    ))}
                </div>
            </div>
        </>
       
    );
}

export default StartMenu;
