import './Win95Window.scss';
import Draggable from 'react-draggable';
import closeIcon from '../../assets/close.png';
import agrandirIcon from '../../assets/agrandir.png';
import minIcon from '../../assets/min.png';
import { useState } from 'react';

function Win95Window({ title, icon, children, onClose,onMinimize, position, content, onWindowClick, id, style }) {
    const [isMaximized, setIsMaximized] = useState(false);
    const [windowPos, setWindowPos] = useState(position);
    const isMobile = window.innerWidth <= 768;

    const mobilePosition = { top: 10, left: 0,width: '100%', height: '70%'}
    
    const desktopPosition = { top: 10, left: 0, width: '33%', height: '90%', minWidth: 500}

    const handleMaximize = () => {
        if (window.innerWidth > 350) {
            if (isMaximized) {
                setWindowPos(position);
            } else {
                if (!isMobile) {
                    setWindowPos({
                        x: (window.innerWidth / 3) , 
                        y: 0
                    });
    
                } else {
                    setWindowPos({
                        x: 0 , 
                        y: 0
                    });
                }
            }
            setIsMaximized(prev => !prev);
        }
        
        
    };
    const windowStyle = isMaximized 
    ? isMobile ? mobilePosition : desktopPosition
    : {};

    const handleDragStop = (e, data) => {
        setWindowPos({ x: data.x, y: data.y });
    };
    return (
        <Draggable
            handle=".title-bar"
            position={windowPos}
            disabled={isMaximized}
            onStop={handleDragStop} 
        >
            <div className="win95-window" onClick={() => onWindowClick(id)} style={{ ...style, ...windowStyle }}>
                <div className="title-bar">
                    <span className="title">
                        <img src={icon} alt="Title icon" className='title-icon'/> 
                        {title}
                    </span>
                    <div className='btns'>
                        <img src={minIcon} alt='min icon' onClick={onMinimize} onTouchStart={onMinimize} />
                        <img src={agrandirIcon} alt='maximize icon' onClick={handleMaximize} onTouchStart={handleMaximize} />
                        <img src={closeIcon} alt='close icon' onClick={onClose} onTouchStart={onClose} />
                    </div>
                </div>
                <div className="content">
                    {content}
                </div>
            </div>
        </Draggable>
    );
}

export default Win95Window;