import homeIcon from './../assets/artage-io.png';
import aboutIcon from './../assets/about.png';
import musicIcon from './../assets/music.png';
import blogIcon from './../assets/blog.png';
import journeyIcon from './../assets/journey.png';
import expgIcon from './../assets/expertise.png';
import behanceIcon from './../assets/gallery.png';

const menuItems = (isMusicPlaying)=>[
    {
        icon: aboutIcon,  // Ensure you have imported or defined this icon
        name: 'About Me',
        title: 'System properties',
        content: '...AboutMeWindow'
    },
    {
        icon: musicIcon,  // Ensure you have imported or defined this icon
        name: isMusicPlaying ? 'Stop Music' : 'Play Music',
        title: isMusicPlaying ? 'Stop Music' : 'Play Music',
        content: 'Music Player',  // This can be anything you like
    },
    { 
        icon: homeIcon, 
        name: 'Home', 
        title: 'C:/ka1s.dev/home', 
        content: <div className="window-content">
                <img className='window-content-icon' src={homeIcon} alt="Description" />
                <span className='highlight-rect'>Who?</span> <br/>
                 I'm a <span className='highlight'>software engineer</span>, focused on modern web technologies like Spring, React, and serverless computing.
                 <br/>
                 <br/>
                 <span>My experience spanning over 6 years and across multiple countries, alongside an <a href='https://www.credly.com/badges/bebcdcff-b198-40c9-8ebb-4480f5b99c4d' target="_blank" rel="noreferrer" className='highlight'>Oracle Java 8 certification</a> , means that I can be trusted to get things done, even when things go wrong.</span>
                <br/><br/>
                <span className='highlight-rect'>Wait... what is this site?</span> <br/>
                <span>This is my personal portfolio, crafted using <span className='highlight'>ReactJS</span>, inspired by the curiosity of what a 90's tech kid's portfolio might look like. </span> <br/>
                <span>Dive in and enjoy the exploration!</span> <br/>
                <br/>
                <span className='highlight-rect'>Click the Start Menu to learn more.</span><br/><br/>
                <span>Contact <span className='highlight'>riahikais@yahoo.com</span></span><br/><br/>
            </div>
    },
    {
        icon: blogIcon,  // Ensure you have imported or defined this icon
        name: 'Blog',
        title: 'C:/ka1s.dev/blog',
        content: <div className="window-content">
                    <img className='window-content-icon' src={blogIcon} alt="Description" />
                    <span className='highlight-rect'>Blog</span> <br/>
                        The most recent posts.
                    <br/>
                    <br/>
                    <a href='https://medium.com/@riahikais/spring-webflux-and-the-gradual-phasing-out-of-resttemplate-why-its-happening-36dd0296eb5f' target="_blank" rel="noreferrer" className='highlight'>Spring WebFlux and the Gradual Phasing Out of RestTemplate: Why It’s Happening</a>
                    <br/>
                    <br/>
                    <a href='https://medium.com/@riahikais/intro-to-inversion-of-control-and-dependency-injection-with-spring-eef7a8c368d1' target="_blank" rel="noreferrer" className='highlight'>Intro to Inversion of Control and Dependency Injection with Spring</a>
                    <br/>
                    <br/>
                    
                 
                 </div>
    },
    {
        icon: journeyIcon,  // Ensure you have imported or defined this icon
        name: 'The Journey',
        title: 'C:/ka1s.dev/journey',
        content: <div className="window-content">
            <img className='window-content-icon' src={journeyIcon} alt="Description" />
            <span className='highlight-rect'>A Software Engineer's Odyssey</span> <br/>
            <span>Every journey begins with a single step. And in the world of software engineering, that step is often characterized by the very first line of code written or the first software project one contributes to.  </span> <br/><br/>
            <span className='highlight-rect'>The Starting Point: Seed Up</span><br/>
            <span>My first professional foray into software development began in <span className='highlight'>2018</span> with Seed Up, a nimble team dedicated to transforming management systems for the food retail sector. Here, I delved deep into the intricacies of module development, source code optimization, and client support. The experience was both exhilarating and challenging, giving me a taste of what lay ahead.</span> <br/><br/>
            <span className='highlight-rect'>The Exploration: Accentia Tunisie</span><br/>
            <span>The subsequent years took me to Accentia Tunisie, where I had the opportunity to work on <span className='highlight'>ERP system integrations</span>, specifically focusing on the automobile dealership niche. With a team of seasoned experts, I helped in developing user interfaces, custom APIs, and collaborated in business workshops to ensure the solutions resonated with the clients' requirements.</span> <br/><br/>
            <span className='highlight-rect'>Broadening Horizons: Bulldozer</span><br/>
            <span>Then came the transition to Bulldozer, a Canadian-Tunisian venture catering to North American clientele. Working on varied projects, I honed my skills in software development, from design to production. Daily meetings, technical retrospectives, and task estimations became a norm, ensuring seamless communication and efficient project delivery.</span> <br/><br/>
            <span className='highlight-rect'>Embracing Challenges: Nantes to Orléans</span><br/>
            <span>My next stint was with a project in <span className='highlight'>Nantes</span>, focusing on the modernization of social declarations. Working alongside a dedicated team of around twenty specialists, our mission was to streamline and unify social contributions' declaration and payment procedures. From there, I moved to <span className='highlight'>Orléans</span>, diving into the SI Formations project. Aimed at revolutionizing tools for the French Ministry of Higher Education and Research, my role was pivotal in developing application codes, assisting in deployment, and managing agile ceremonies.</span> <br/><br/> <br/>
            <span>In retrospect, every project, every role, and every challenge has been a stepping stone, shaping my professional trajectory. It's been a journey of growth, perseverance, and constant evolution. And as with every journey, the road ahead promises new learnings, opportunities, and milestones.</span><br/> <br/>
        </div>
    },
    {
        icon: expgIcon,  // Ensure you have imported or defined this icon
        name: 'Expertise',
        title: 'C:/ka1s.dev/expertise',
        content: <div className="window-content">
                <img className='window-content-icon' src={journeyIcon} alt="Description" />
                <span className='highlight-rect'>An In-Depth Look into My Expertise</span><br/>
                <span>As you navigate this website, you're experiencing the dynamism and responsiveness of <span className='highlight'>React.js</span> firsthand — the very framework that powers it.  </span> <br/><br/>
                <span>Over the years, through diverse projects and multifaceted roles, I've cultivated a robust technical proficiency that spans across multiple domains and tools. Let's delve deeper into what I bring to the table.</span><br/> <br/>
                <span className='highlight-rect'>Programming Paradigms and Frameworks</span><br/>
                <span><span className='highlight'>Java and Spring Ecosystem:</span><br/> My journey began with mastering Java, a foundational pillar of my technical skillset. Over time, I've honed expertise in the Spring Framework and Spring Boot, enabling me to develop versatile and scalable back-end systems. In tandem, JavaServer Pages (JSP) further augmented my capabilities in creating dynamic web content.</span><br/> <br/>
                <span><span className='highlight'>JavaScript and Beyond:</span><br/> Venturing into the world of JavaScript, I became proficient in TypeScript, which offers a richer development experience with static typing. My skills in React.js empower me to craft responsive and interactive UIs, ensuring users are met with seamless experiences.</span><br/> <br/>
                <span><span className='highlight'>Microservices Architecture:</span><br/> Modern applications demand flexibility, scalability, and resilience. I've been an integral part of projects, such as the SI Formations for the French Ministry, that hinge on a microservices architecture, emphasizing modular and maintainable systems.</span><br/> <br/>
                <br/>
                <span className='highlight-rect'>Tools of the Trade</span><br/>
                <span><span className='highlight'>Version Control with Git:</span><br/> Be it Git or Gitlab, I've integrated version control into my workflow, ensuring codebase integrity, facilitating collaboration, and tracking changes meticulously.</span><br/> <br/>
                <span><span className='highlight'>Database Management:</span><br/> PostgreSQL stands out as a robust relational database system in my toolkit. My understanding of it ensures data integrity, optimization, and security.</span><br/> <br/>
                <span><span className='highlight'>Quality Assurance and CI/CD:</span><br/> Tools like Jenkins and Sonarqube have been instrumental in automating and enhancing my DevOps workflows. They ensure that the code not only functions as expected but adheres to the highest quality standards.</span><br/> <br/>
                <span><span className='highlight'>Middleware and Messaging:</span><br/> RabbitMQ has been pivotal in building decoupled and scalable systems, ensuring reliable message delivery between application components.</span><br/> <br/>
                <span><span className='highlight'>Cloud Solutions:</span><br/> My experience extends to cloud platforms like Microsoft Azure, empowering me to deploy, manage, and scale applications in the cloud with ease.</span><br/> <br/>
                <span><span className='highlight'>Testing and Mocking:</span><br/> With tools like Mockito and Vitest, I've emphasized the importance of thorough testing, ensuring that applications are robust and error-free.</span><br/> <br/>
                <span><span className='highlight'>Project Management and Agile:</span><br/> Scrumban, a fusion of Scrum and Kanban, has been my go-to methodology, promoting flexibility, iterative development, and continuous improvement.</span><br/> <br/>

                <br/>
                <span>In summation, my technical prowess is not just about knowing tools or languages; it's about weaving them together to create solutions that are efficient, scalable, and user-centric. From databases to cloud solutions, from front-end frameworks to back-end systems, I pride myself on being a holistic developer, ready to tackle challenges and drive innovations.</span>

        </div>
    },
    {
        icon: behanceIcon,  // Ensure you have imported or defined this icon
        name: 'Gallery',
        title: 'C:/ka1s.dev/expertise',
        content: ''
    }
];

export default menuItems;
