import { useState, useEffect, useRef } from 'react';
import './Taskbar.scss';
import StartButton from '../Start/Start';

function Taskbar({ isStartMenuOpen, toggleStartMenu, openWindow, openWindows, windowVisibility, toggleWindowVisibility, isMusicPlaying}) {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  
  const taskbarRef = useRef(null);
  const [displayedWindows, setDisplayedWindows] = useState([]);

  useEffect(() => {
    if (!taskbarRef.current) return;
    const taskbarWidth = taskbarRef.current.offsetWidth;
    const availableWidthForTabs = taskbarWidth - 300;
    setDisplayedWindows(openWindows.slice(0, availableWidthForTabs / 120));
  }, [openWindows]);

  return (
    <div className="taskbar" ref={taskbarRef}>
      <StartButton isStartMenuOpen={isStartMenuOpen} toggleStartMenu={toggleStartMenu} openWindow={openWindow} isMusicPlaying={isMusicPlaying} />
      <div className="window-tabs">
        {displayedWindows.map((window, index) => (
            <div 
                key={window.id} 
                className={`window-tab ${windowVisibility[window.id] ? 'active' : ''}`}
                onClick={() => toggleWindowVisibility(window.id)}
            >
                <img src={window.icon} alt='icon' className='icon'/>
                <span>{window.name}</span>
            </div>
        ))}
    </div>
    
      <div className="time-display">{currentTime}</div>

    </div>
  );
}

export default Taskbar;