import React, { useEffect, useRef, useState } from 'react';
import './Stars.scss';
import './Desktop.scss';
import Taskbar from '../Taskbar/Taskbar';
import Win95Window from '../Win95Window/Win95Window';
import { v4 as uuidv4 } from 'uuid';
import menuItems from '../../utils/menu-data';
import AboutMeWindow from '../AboutMeWindow/AboutMeWindow';
import audioFile from '../../assets/audio.mp3';
import folderIcon from '../../assets/folder.png';  // Adjust the path accordingly
import astrIcon from '../../assets/astr.png';  // Adjust the path accordingly
import cloud1Icon from '../../assets/cloud1-.png';  // Adjust the path accordingly
import cloud2Icon from '../../assets/cloud2-.png';  // Adjust the path accordingly
import cloud3Icon from '../../assets/cloud3-.png';  // Adjust the path accordingly
import cloud4Icon from '../../assets/cloud4-.png';  // Adjust the path accordingly
import cloud5Icon from '../../assets/cloud4-.png';  // Adjust the path accordingly
import cloud6Icon from '../../assets/cloud6-.png';  // Adjust the path accordingly
import behanceIcon from '../../assets/behance.png';  // Adjust the path accordingly
import musicdesktopIcon from '../../assets/music-desktop.png';  // Adjust the path accordingly
import { PowerGlitch } from 'powerglitch'

type WindowType = {
  id: number;
  title: string;
  icon: string;
  name: string;
  content: React.ReactNode;
  position: { x: number; y: number };
}

function Desktop() {
  const [isStartMenuOpen, setStartMenuOpen] = useState(false);
  const initialWindowId = uuidv4();;
  const [topmostWindowId, setTopmostWindowId] = useState<number | null>(null);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const audioRef = useRef(null);
  const currentMenuItems = menuItems(isMusicPlaying);
  const homeMenuItem = currentMenuItems.find(item => item.name === 'Home');
  const [musicLabel, setMusicLabel] = useState('Play Music');
  const isMobile = window.innerWidth <= 521;

  useEffect(() => {
    // This function checks if '.glitch' exists and then runs the PowerGlitch function
    const checkForElementAndExecute = () => {
      const glitchElem = document.querySelector('.glitch');
      const glitchAstr = document.querySelector('.astr-img');
      const glitchContainer = document.querySelector('.container');
      if (glitchElem && glitchAstr && glitchContainer) {
        PowerGlitch.glitch('.glitch',{
          "timing": {
            "duration": 5950,
            "easing": "ease-in-out"
          },
          "shake": {
            "velocity": 1,
            "amplitudeX": 0,
            "amplitudeY": 0
          },
          "slice": {
            "count": 5,
            "velocity": 10,
            "minHeight": 0.02,
            "maxHeight": 0.15,
            "hueRotate": true
          }
          });
          PowerGlitch.glitch('.astr-img',{
            "timing": {
              "duration": 5950,
              "easing": "ease-in-out"
            },
            "shake": {
              "velocity": 1,
              "amplitudeX": 0,
              "amplitudeY": 0
            },
            "slice": {
              "count": 3,
              "velocity": 2,
              "minHeight": 0.02,
              "maxHeight": 0.15,
              "hueRotate": true
            }
            });

            PowerGlitch.glitch('.container',{
              "timing": {
                "duration": 7950,
                "easing": "ease-in-out"
              },
              "shake": {
                "velocity": 1,
                "amplitudeX": 0,
                "amplitudeY": 0
              },
              "slice": {
                "count": 3,
                "velocity": 2,
                "minHeight": 0.02,
                "maxHeight": 0.15,
                "hueRotate": true
              }
              });
      } else {
        // Retry after some time (e.g., 100ms)
        setTimeout(checkForElementAndExecute, 100);
      }
    };

    // Start the checking process
    checkForElementAndExecute();
  }, []); // Empty dependency array ensures this useEffect runs once after component mounts

  const mobilePosition = {
    x: (0.5 * window.innerWidth) - (320 / 2), // Adjust as needed for mobile
    y: (0.45 * window.innerHeight)
  };
  
  const desktopPosition = {
    x: (0.76 * window.innerWidth) - (400 / 2),
    y: 0.35 * window.innerHeight
  };

  const desktopInitialState: WindowType[] = [{
    id: initialWindowId,
    ...homeMenuItem,
    position: isMobile ? mobilePosition : desktopPosition
  }];
  
  const [openWindows, setOpenWindows] = useState<WindowType[]>(isMobile ? [] : desktopInitialState);

  const [windowVisibility, setWindowVisibility] = useState({
    [initialWindowId]: true
  });

  const openWindow = (title, icon, content, name) => {

    if (name === "Play Music" || name === "Stop Music") {
      playMusic();
    } else {
      const id = uuidv4();
      let  position;
      if (name === 'About Me'){
        position = {
          x: 0,
          y: 0
        };  
      } else {
        position = {
          x: 0.6 * window.innerWidth - (400 / 2) + (openWindows.length % 10) * 30,
          y: 0.3 * window.innerHeight + (openWindows.length % 10) * 30 
        };  
      }
      
      const newWindow = { id, title, position, icon, content, name };
      setOpenWindows(prevWindows => [...prevWindows, newWindow]);
      setWindowVisibility(prevVisibility => ({
          ...prevVisibility,
          [id]: true
        }));
    }
  }

  const closeWindow = (id) => {
    setOpenWindows(prevWindows => prevWindows.filter(window => window.id !== id));
  }

  const toggleWindowVisibility = (windowId) => {
    setWindowVisibility(prevState => ({
        ...prevState,
        [windowId]: !prevState[windowId]
    }));
    setTopmostWindowId(windowId);
  };


  const playMusic = () => {
        if (!audioRef.current) {
            audioRef.current = new  Audio(audioFile);
        }

        if (isMusicPlaying) {
          audioRef.current.pause();
          setMusicLabel('Play Music');  // Change the label when music is stopped
        } else {
            audioRef.current.play();
            setMusicLabel('Stop Music');  // Change the label when music is played
        }
    
        // Toggle the isMusicPlaying state
        setIsMusicPlaying(prevState => !prevState);

  };

  function handleFolderClick() {
    const aboutMeItem = currentMenuItems.find(item => item.name === 'About Me');
       
    if (aboutMeItem) {
        openWindow(aboutMeItem.title, aboutMeItem.icon, aboutMeItem.content, aboutMeItem.name);
    }
  }
  function handlePlayClick() {
    playMusic()
  }

  
  return (
    <div className="desktop">
      <section className="wrapper wrapper-stars-1">
              <div id="stars"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>
      </section>
      <section className="wrapper wrapper-stars-2">
              <div id="stars"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>
      </section>
      
      <div className='stars-contrainer'>
        <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>

      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>

      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>
      
      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>
      
      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>
      
      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>

      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>

      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>
      
      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>
      
      <div className="shootingStarContainer">
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
          <div className="shootingStar"></div>
      </div>


      <div className="stars"></div>
      <div className="twinkleMask"></div>
      <div className="twinkleMask2"></div>
      <div className="clouds"></div>

      <div className="fogContainer">
          <div className="fog"></div>
      </div>
    </div>

      <span className="border">
        <span className="gradient"></span>
        <span className="container"></span>
      </span>

      <div className='astr'><img className='astr-img' src={astrIcon} alt='Astraunaut'/></div>
      
      <div className='clouds-container'>
        <img src={cloud1Icon} alt='cloud1Icon' />
        <img src={cloud2Icon} alt='cloud2Icon'/>
        <img src={cloud3Icon} alt='cloud3Icon'/>
        <img src={cloud4Icon} alt='cloud4Icon' className='fourth-img'/>
        <img src={cloud5Icon} alt='cloud5Icon'/>
        <img src={cloud6Icon} alt='cloud6Icon'/>
        <img src={cloud3Icon} alt='cloud3Icon'className='glitch'/>
        <img src={cloud3Icon} alt='cloud3Icon'/>
        <svg width="100%" height="100%" viewBox="0 0 606 601" style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}>
          <rect id="Artboard1" x="0.184" y="0.156" width="604.914" height="600.235" style={{ fill: "none" }} />
          <circle cx="296.219" cy="296.191" r="296.035" fill="url(#_Radial1)" />
          <defs>
            <radialGradient id="_Radial1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(247.484,454.27,-454.27,247.484,198.862,99.832)">
              <stop offset="0" stopColor="#6c00ff" stopOpacity="1" />
              <stop offset="0.47" stopColor="#9401e7" stopOpacity="1" />
              <stop offset="1" stopColor="#ff03a7" stopOpacity="1" />
            </radialGradient>
          </defs>
        </svg>
        
      </div>

      <div className="folder-icon" onDoubleClick={handleFolderClick} onTouchStart={handleFolderClick} >
          <img src={folderIcon} alt="Folder" />
          <span>ka1s.dev</span>
      </div>

      <a href="https://www.behance.net/devkais" target="_blank" rel="noopener noreferrer">
          <div className="behance-icon">
              <img src={behanceIcon} alt="behance" />
              <span>Gallery</span>
          </div>
      </a>


      <div className="music-icon" onDoubleClick={handlePlayClick} onTouchStart={handlePlayClick} >
        <img src={musicdesktopIcon} alt="Music" />
        <span>{musicLabel}</span>
      </div>
      <Taskbar 
        isStartMenuOpen={isStartMenuOpen} 
        toggleStartMenu={() => setStartMenuOpen(!isStartMenuOpen)} 
        openWindow={openWindow}
        windowVisibility={windowVisibility}
        toggleWindowVisibility={toggleWindowVisibility}
        openWindows={openWindows}
        isMusicPlaying={isMusicPlaying} 
        />

      {openWindows.map(window => (
          windowVisibility[window.id] && (
              window.name === 'About Me' ? (
                  <AboutMeWindow
                      key={window.id}
                      onClose={() => closeWindow(window.id)}
                      onWindowClick={() => setTopmostWindowId(window.id)}
                      id={window.id}
                      style={{ zIndex: 12 }}
                      onMinimize={() => toggleWindowVisibility(window.id)}
                      position={window.position}
                  />
              ) : (
                  <Win95Window 
                      key={window.id} 
                      title={window.title} 
                      content={window.content} 
                      icon={window.icon} 
                      position={window.position}
                      onClose={() => closeWindow(window.id)}
                      onWindowClick={() => setTopmostWindowId(window.id)}
                      id={window.id}
                      style={{ zIndex: window.id === topmostWindowId ? 13 : 12 }}
                      onMinimize={() => toggleWindowVisibility(window.id)}
                  >
                      {/* You can put content here based on the window title if needed */}
                  </Win95Window>
              )
          )
      ))}
      
    </div>
  );
}

export default Desktop;